<template>
  <div class="container">
    <div class="part" v-for="item in menu"  v-if="userInfo.role >= item.role">
      <h5>{{item.title}}</h5>
      <section>
        <div
        class="item"
        v-for="child in item.children"
        @click="router(child)"
        v-if="userInfo.role >= child.role"
      >
        <img v-if="child.image" :src="require(`../assets/menus/${child.image}`)" alt="" />
        <p>{{ child.name }}</p>
      </div>
      </section>
    </div>
    
  </div>
</template>

<script>
export default {
  inject: ["wxJS"],
  data() {
    return {
      menu: [
        {
          title:'管理',
          children: [
            {
              name: "人员情况",
              image: "1.png",
              url: "personList",
              role: 1,
            },
            {
              name: "员工状态查询",
              image: "2.png",
              url: "PersonList",
              role: 1,
            },
            {
              name:"疫情状况",
              image:"11.png",
              url:"Management",
              role:1
            },
          ],
          role:1
        },
        {
          title:'登记',
          children: [
            {
              name: "个人信息",
              image: "3.png",
              url: "Info",
              role: 0,
            },
            // {
            //   name: "回国登记",
            //   image: "4.png",
            //   url: "Home",
            //   role: 0,
            // },

            {
              name: "行程登记",
              image: "5.png",
              url: "Journey",
              role: 0,
            },{
              name:'',
              role:0
            },{
              name:'',
              role:0
            },
            {
              name: "核酸检测",
              image: "6.png",
              url: "Epidemic",
              role: 0,
            },
            {
              name: "接种疫苗",
              image: "7.png",
              url: "Vaccine",
              role: 0,
            },
            {
              name: "隔离登记",
              image: "8.png",
              url: "Quarantine",
              role: 0,
            },
            {
              name: "体温",
              image: "9.png",
              url: "Temperature",
              role: 0,
            },
          ],
          role:0
        },
        // {
          
        //   children: [
            
        //   ],
        // },
        {
          title:'查询',
          children: [
            {
              name: "风险地区",
              image: "10.png",
              url: "EpidemicArea",
              role: 0,
            },{
              name:"政策查询",
              image:"12.png",
              url:"Policy",
              role:0
            },{
              name:"防疫知识",
              image:'13.png',
              url:'EpidemicKnowledge',
              role:0
            }
          ],
          role:0
        },
      ],
      userInfo: JSON.parse(this.$store.state.userInfo.detail),
    };
  },
  mounted() {
    this.$store.state.search = []
    let _this = this;
    this.wxJS();
    this.$store.commit("clearCache", {});
    addEventListener(
      "popstate",
      function () {
        if (
          history.length >= 3 &&
          (_this.path == "/" || _this.path == "/position")
        ) {
          window.close();
        }
      },
      false
    );
  },
  methods: {
    router(child) {
      if (child.url) {
        this.$router.push({ name: child.url });
      }
    },
  },
};
</script>

<style scoped>
.container{
  padding: 0.1rem;
}
.part {
  /* border-bottom: 1px solid rgba(112, 112, 112, 0.25); */
  background: #fff;
  border-radius: 14px;
  margin: 0.1rem 0;
}
.part>h5{
  padding: 0.05rem .2rem;
  font-size: 0.15rem;
  font-weight: normal;
  color: #666;
  border-bottom: 1px solid #f5f5f5;
}
.part section{
  padding: 1px 0.13rem;
  display: flex;
  flex-wrap: wrap;
}
.part:last-child {
  border-bottom: none;
}
.part .item {
  width: 25%;
  text-align: center;
  margin: 0.05rem 0 0.17rem;
}
.part .item p {
  color: #0c0c0c;
  line-height: 0.18rem;
  font-size: 0.13rem;
}
.part .item img {
  width: 0.5rem;
}

</style>